// @ts-nocheck
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import StorageProvider from '../../../framework/src/StorageProvider';
import {
  ToastAndroid,
  Platform
} from "react-native";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { RouteProps } from "react-router";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export type Props =  RouteProps & {
  id: string;
  openDialog : any;
    showNotification : any;
    showLoader : any;
    hideLoader : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  phoneNumber: any;
  otp: any;
  currentCountryCode: any;
  loading: boolean;
  phoneNumberError: string;
  otpError: string;
  loginOTPData:any;
  errorMessage: string;
  isClearCalled: boolean;
  newValue: any,
  value: any,
  alertMessage: any,
  alertOpen: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PhoneLoginController extends BlockComponent<
  Props,
  S,
  SS
  > {
  // Customizable Area Start
  labelPhoneNumber: string;
  labelNextBtn: string;
  labelLogin: string;
  labelPasscode: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  defaultCountryCode: String;
  labelSingUp: string;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  apiGetLoginOTPLCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.ReciveUserCredentials),
       getName(MessageEnum.AlertMessage)
    ]

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      currentCountryCode: "91",
      enableField: false,
      loading: false,
      otp: "",
      phoneNumberError: "",
      otpError: "",
      errorMessage: "",
      isClearCalled: false,
      loginOTPData:[],
      otp: "",
      currentCountryCode: "91",
      newValue: "",
      value: "Customer",
      alertMessage: "",
      alertOpen: false,
    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelNextBtn = configJSON.labelNextBtn;
    this.labelLogin = configJSON.labelLogin;
    this.labelPasscode = configJSON.labelPasscode;
    this.labelEnterPhone = configJSON.labelEnterPhone;
    this.labelDontHaveAccount = configJSON.labelDontHaveAccount;
    this.labelSingUp = configJSON.labelSingUp;
    this.defaultCountryCode = configJSON.defaultCountryCode;
    this.phoneReg = new RegExp(/^[0-9]+$/);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    console.log("API RESPONSE===>", message);


    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });

    // }

    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      runEngine.debugLog("selectedCode", selectedCode);
      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode: selectedCode?.value
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

       if(apiRequestCallId === this.apiGetLoginOTPLCallId){
        await this.handleApiResponse(responseJson)
      }
    } else if(getName(MessageEnum.AlertMessage) === message.id){
        const title = message.getData(
          getName(MessageEnum.AlertTitleMessage)
        );

        var AlertBodyMessage = message.getData(
          getName(MessageEnum.AlertBodyMessage)
        );

        if (AlertBodyMessage === "Invalid passcode") {
          this.setState({
            otp: "",
            isClearCalled: true,
            // loginError : AlertBodyMessage
          });
        } else {
          this.setState({
            phoneNumber: "",
            otp: "",
            isClearCalled: true,
            // loginError : AlertBodyMessage
          });
        }
        
        this.props.showNotification(AlertBodyMessage,'error');  
    }
    // Customizable Area End
  }

  // Customizable Area Start

  onChangePhoneNumber = (event:any) => {
    this.setState({phoneNumber: event.target.value});
  }

  async handleApiResponse(responseJson) {
    this.setState({ loading: false });
    this.props.hideLoader();
  
    if (responseJson && responseJson.data) {
      let data = {
        token: responseJson.meta.token,
        full_phone_number: responseJson.data.attributes.full_phone_number
      };
  
      await StorageProvider.set('OtpData', JSON.stringify(data));
      await StorageProvider.set('user_type',  this.state.value);
      this.props.history.push("LoginVerifyOtp");
  
      this.setState({
        loginOTPData: responseJson.data,
      });
    } else {
      this.props.showNotification(responseJson.errors[0].error, 'error');
    }
  }


  //STRAT --here we are validating the phone number and country code and making API call to login user 

  loginOTPAPICall() {
    let mobileNo: any = this.state.phoneNumber;
    const validatePhoneNumberError = this.validatePhoneNumber(mobileNo);

    if (validatePhoneNumberError) {
      this.setState({phoneNumberError: validatePhoneNumberError});
      return;
    }
    this.setState({ loading: true });

    //here we need to call GET OTP API CALL
    let countryCode: any = this.state.currentCountryCode;
    countryCode = countryCode.indexOf("+") > 0
      ? countryCode.split("+")[1]
      : countryCode

    this.apiType = "sms_account"
    this.attrs = {
      full_phone_number: countryCode + mobileNo,
      user_type: this.state.value 
    }

    const header = {
      "Content-Type": configJSON.apiContentType
    };

    const data = {
      type: this.apiType,
      attributes: this.attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetLoginOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return;
  }
  //END --here we are validating the phone number and country code and making API call to login user 

  validatePhoneNumber(phoneNumber: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(phoneNumber)) {
      error = "Phone number " + configJSON.errorBlankField;
    } else if (!this.isValid10DigitPhoneNumber(phoneNumber)) {
      error = configJSON.errorMobileNoNotValid;
    }
    return error;

  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  isValid10DigitPhoneNumber(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 10
    );
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  // Customizable Area End
}
