//@ts-nocheck
import React from "react";
// Customizable Area Start
import {Alert} from 'react-native'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { APP_FONTS } from '../../../components/src/AppFonts';
import StorageProvider from '../../../framework/src/StorageProvider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputBase from '@material-ui/core/InputBase';

import OrderPaymentDetailsController, {
  Props
} from "./OrderPaymentDetailsController.Web";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withLoaderPayment } from "../../../components/src/withLoaderPayment.Web";
import withRouter from "react-router/withRouter";
import CouponsWeb from "../../user-profile-basic/src/Coupons.Web";
// Customizable Area End
class OrderPaymentDetails extends OrderPaymentDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  _backTapped = () => { this.props.navigation.goBack() };

  confirmationDialog = () => {
    Alert.alert(
      "Place Order!!!",
      "Are you sure want to place order?",
      [
        {
          text: "No",
          style: "cancel"
        },

        { text: "Yes", onPress: () => this.placeOrderApiCall() }
      ],
      { cancelable: true }
    );
  }
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    this.props.showLoader()
    await StorageProvider.get('loginData').then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          authToken: loginData.token,
          id: loginData.id
        });
      }
    });
    await StorageProvider.get('orderId').then((res) => {
      if (res) {
        const orderIdData = JSON.parse(res);

        if (orderIdData) {
          this.setState({
            goBack: orderIdData.goBack,
          });
        }
      }
    });
    await StorageProvider.get('orderIdDetails').then((res) => {

      function isJson(str) {
        try {
          JSON.parse(str);
        } catch (e) {
          return str;
        }
        return JSON.parse(str);
      }

      if (res) {
        const orderIdDetails = isJson(res);
        this.setState({
          orderId: parseInt(orderIdDetails.id),
          orderIsCreated: true
        })
      }
    });

    await StorageProvider.get('selectedFiles').then((res) => {
      if (res) {
        const selectedFiles = JSON.parse(res);
        this.setState({
          // orderId: selectedFiles,
          attachmentIds: selectedFiles
        })
      }
    });

    this.getOrderAmountDetailsApiCall();
    this.getAttachmentAmount();

    this.getPaymentStatus();
    const MINUTE_MS=1000;  
    const interval = setInterval(async () => {
      await StorageProvider.get('isRazorpayOpen').then((res) => {
        if (res) {
          const isRazorpayOpen = JSON.parse(res);
          if(this.state.status === 'Pending' && isRazorpayOpen){
            this.getPaymentStatus();
          }
        }
      });
    }, MINUTE_MS);
    this.setState({ intervalId: interval });
    // Customizable Area End
  }

  // Customizable Area Start  
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  _handleBackClicked = () => {
    if (this.props.location?.state?.calledFrom === "orderHistory") {
      this.props.history.push('/OrderManagement/OrderHistory/History')
    }
    else {
      const options = {
        pathname: '/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen',
        state: { calledFrom: "PaymentScreen" }
      }
      this.props.history.push(options)
    }
  }

  openUpiModelDialog = () => {
    this.setState({ payWithUpi: true });
  }

  closeUpiModelDialog = () => {
    this.setState({ payWithUpi: false });
  }

  handleInputChange = (event: any) => {
    this.setState({ upiId: event.target.value });
  }
  setCouponData = (selectedCouponData:any) => {
    this.setState({...this.state,...selectedCouponData})
  }

  handleRazorpayPayment =() =>{
    if (this.state.orderIsCreated) {
      this.createTransactionApiCall()
    } else {
      this.createOrder()
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <div className={classes.mainContentWrap}>
        <Grid item xs={12} className={classes.benefitsTop}>
          <Typography variant="h4" className={classes.selectFilesText}>Benefits</Typography>
          <Typography className={classes.selectFilesText}>Volume Discount</Typography>
          <Box className={classes.benefitsBoxWrap}>
            <Box className={classes.benefitsTopBox}>
              <Typography variant="h4">100-200 page</Typography>
              <Typography>5% off</Typography>
            </Box>
            <Box className={classes.benefitsTopBox}>
              <Typography variant="h4">100-200 page</Typography>
              <Typography>5% off</Typography>
            </Box>
            <Box className={classes.benefitsTopBox}>
              <Typography variant="h4">100-200 page</Typography>
              <Typography>5% off</Typography>
            </Box>
            <Box className={classes.benefitsTopBox}>
              <Typography variant="h4">100-200 page</Typography>
              <Typography>5% off</Typography>
            </Box>
          </Box>
        </Grid>
        <div className={classes.mainContent}>
          <Grid className={classes.rightContent}>
            <Grid item xs={12} className={classes.headingCont}>
              <Typography className={classes.selectFilesText}>Select Payment Method</Typography>
            </Grid>
            <Grid container className={classes.paymentCont}>
              <Grid item xs={6} sm={6} md={3} className={classes.upiCont}>
                <div className={classes.upiContent}
                  onClick={() => this.openUpiModelDialog()}>
                  <img src={require('../assets/group-3.svg')} alt="" className={classes.upiImg} />
                  <div>
                    <Typography className={classes.payByText}>Pay By</Typography>
                    <Typography className={classes.payMethodText}>UPI</Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={3} className={classes.upiCont}>
                <div className={classes.upiContent} onClick={() => {
                  this.setState({ payment_via: "Razorpay" }, () => {
                    this.props.showLoader();
                    this.handleRazorpayPayment();
                  });
                }}
                >
                  <img src={require('../assets/card.svg')} alt="" className={classes.upiImg} />
                  <div>
                    <Typography className={classes.payByText}>Pay By</Typography>
                    <Typography className={classes.payMethodText}>Other Methods</Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className={classes.detailsCont}>
                
                <div className={classes.detailsText}>
                  <Typography className={classes.detailsAmountText}>Total Grayscale Pages</Typography>
                  <Typography className={classes.detailsAmountText}> &nbsp;{this.state.attachmentDetails.filter((item) => item.colour == "Greyscale").reduce((acc,curr) => acc + curr.number_of_copies*curr.total_pages, 0)}</Typography>
                </div>
                <div className={classes.detailsText}>
                  <Typography className={classes.detailsAmountText}>Total Colored Pages</Typography>
                  <Typography className={classes.detailsAmountText}> &nbsp;{this.state.attachmentDetails.filter((item) => item.colour == "Colored").reduce((acc,curr) => acc + curr.number_of_copies*curr.total_pages, 0)}</Typography>
                </div>
                <div className={classes.detailsText}>
                  <Typography className={classes.detailsAmountText}>Order Amount</Typography>
                  <Typography className={classes.detailsAmountText}> &nbsp;{'Rs.' + (this.state.orderAmount ? parseFloat(this.state.orderAmount).toFixed(2) : "00.00")}</Typography>
                </div>
                {this.state.couponCode && <div className={classes.detailsText}>
                  <Typography className={classes.detailsAmountText}>Coupons Saving</Typography>
                  <Typography className={classes.detailsAmountText}> &nbsp;{(this.state.couponCode)}</Typography>
                </div>}
                {this.state.updated_total_amount && <div className={classes.detailsText}>
                  <Typography className={classes.detailsAmountText}><span>Discount Amount</span></Typography>
                  <Typography className={classes.detailsAmountText}><span> &nbsp;{'-Rs.' + (this.state.totalAmount - this.state.updated_total_amount )}</span></Typography>
                </div>}
                <div className={classes.detailsText}>
                  <Typography className={classes.detailsAmountText}>Taxes</Typography>
                  <Typography className={classes.detailsAmountText}> &nbsp;{'Rs.' + (this.state.taxAmount ? parseFloat(this.state.taxAmount).toFixed(2) : "00.00")}</Typography>
                </div>
                {this.state.updated_total_amount ? 
                <div className={classes.detailsText}>
                <Typography className={classes.detailsTotalText}>Total Amount</Typography>
                <Typography className={classes.detailsTotalText}>{'Rs.' + (this.state.updated_total_amount ? parseFloat(this.state.updated_total_amount).toFixed(2) : "00.00")}</Typography>
              </div> :
                <div className={classes.detailsText}>
                  <Typography className={classes.detailsTotalText}>Total Amount</Typography>
                  <Typography className={classes.detailsTotalText}>{'Rs.' + (this.state.totalAmount ? parseFloat(this.state.totalAmount).toFixed(2) : "00.00")}</Typography>
                </div>}
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.cardContent}>
            <CouponsWeb 
              isPayment={true}
              order_id={this.state.orderId}
              account_id={this.state.id}
              setCouponData={this.setCouponData} 
            />
          </Grid>
          <Dialog
            open={this.state.payWithUpi}
            onClose={this.closeUpiModelDialog}
            aria-labelledby="alert-dialog-title"
            className="raise-concern-dialog popup-custom"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"
            >
              <span className={classes.upiPayTxt}>Pay with UPI</span>
              <span className="cross-md" onClick={this.closeUpiModelDialog}>
                <img src={require('../assets/upi_model_close.png')} alt="" className={classes.upiImg} />
              </span>
            </DialogTitle>
            <DialogContent>
              <InputBase
                name="upi"
                value={this.state.upiId}
                onChange={(e) => { this.handleInputChange(e) }}
                placeholder="Type your UPI here"
                style={{ border: '1px solid lightgray' }}
                className="textFieldInput-raise-concern"
              />
              <Button
                style={{ backgroundColor: "#32795f" }}
                className={classes.closeModalBtn}
                onClick={() => {
                  this.setState({ payment_via: "" }, () => {
                    if (this.state.orderIsCreated) {
                      this.payWithUpiMethod()
                    } else {
                      this.createOrder()
                    }
                  })
                }}
              >
                Submit
              </Button>
            </DialogContent>
          </Dialog>

        </div>
      </div>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    mainContentWrap: {
      width: '100%'
    },
    mainContent: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 15,
      width: '100%',
    },
    rightSideCont:
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    totalCont: {
      width: '90%',
      margin: "auto"
    },
    rightContent: {
      position: "relative",
      width: '50%',
      background: `#f7f7f7`,
      borderRadius: 12,
      marginBottom: 40,
      height: 'fit-content'
    },
    headingCont: {
      width: '90%',
      display: "flex",
      margin: "20px",
      alignItems: "center"
    },
    paymentCont: {
      width: '100%',
      display: "flex",
      margin: "20px 0",
      padding: "0 20px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    upiCont: {
      height: 220,
      marginTop: 20,
      cursor: "pointer"
    },
    upiContent: {
      width: '90%',
      display: "flex",
      height: '100%',
      borderRadius: 12,
      flexDirection: "column",
      justifyContent: "space-evenly",
      margin: "auto",
      backgroundColor: "#ffffff",
    },
    detailsText: {
      width: '100%',
      margin: "5px auto",
      padding: "0 0 0 15px",
      display: "flex",
      justifyContent: "space-between",
      '& p:first-child': {
        minWidth: "75%",
        width: "75%",
      },
      '& p:last-child': {
        textAlign: "right",
      },
      '& span': {
        color: '#1e1f20',
      }
    },
    closeModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "50%",
      marginTop: "20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      fontSize: 14
    },
    selectFilesText: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color: '#1e1f20'
    },
    payByText: {
      fontSize: 16,
      marginLeft: '20%',
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color: '#8f92a1',
      marginTop: 8
    },
    payMethodText: {
      fontSize: 17,
      marginLeft: '20%',
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color: '#1e1f20',
      marginTop: 8
    },
    detailsAmountText: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color: '#8f92a1',
      marginTop: 8
    },
    detailsTotalText: {
      fontSize: 17,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color: '#1e1f20',
      marginTop: 20
    },
    navBar: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      margin: "20px 30px"
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: '#32795f',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: '#1e1f20'
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: 'contain'
    },
    backImg: {
      height: 40,
      width: 40,
      cursor: "pointer",
      objectFit: 'contain'
    },
    upiImg: {
      height: 45,
      width: 45,
      marginLeft: '20%',
      objectFit: 'contain'
    },
    cardContent: {
      width: '50%',
      height: 'calc(100vh - 160px)',
      '& div': {
        backgroundColor: '#f7f7f7',
        borderRadius: 12,
        height: 'auto',
      },
    },
    benefitsBoxWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '20px',
    },
    benefitsTop: {
      marginBottom: 20,
      '& h4': {
        fontSize: 26,
        fontWeight: 'bold',
        marginLeft: 0,
        marginBottom: 15,
      },
      '& p': {
        marginLeft: 0,
        marginBottom: 15
      }
    },
    benefitsTopBox: {
      textAlign: 'center',
      border: '1px dashed #ccc',
      borderRadius: '12px',
      padding: '5px',
      boxShadow: "0 2px 7px 0 rgba(147, 147, 147, 0.5)",
      '& h4': {
        color: '#8f92a1',
        fontSize: 16,
        fontWeight: 'normal',
        margin: '15px 0',
      },
      '& p': {
        color: '#32795f',
        fontSize: 18,
        fontWeight: 'bold',
      }
    }
  });
// Customizable Area End
const OrderPaymentDetailsWithConfirmBox = withConfirmBox(OrderPaymentDetails);
const OrderPaymentDetailsWithLoader = withLoader(OrderPaymentDetailsWithConfirmBox);
const OrderPaymentDetailsWithToast = withToast(OrderPaymentDetailsWithLoader);
const OrderPaymentDetailsWithPaymentLoader = withLoaderPayment(OrderPaymentDetailsWithToast);
export default withRouter(withStyles(styles, { withTheme: true })(OrderPaymentDetailsWithPaymentLoader));