// @ts-nocheck
import React from "react";
// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import withRouter from "react-router/withRouter";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  userLogo,
  storeLogo,
  colorLogo,
  group8,
  group3,
  group2,
  group6,
  group4,
  group5,
  group,
  backArrowIcon
} from "./assets";
// Customizable Area End
import CustomCheckBox from "../../../components/src/CustomCheckBox";
import OTPInputView from "../../../components/src/OtpInput.web";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import "./css/style.css";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import "./css/style.css";

import SignUpController, {
  Props,
  configJSON
} from "./SignUpController.web";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import Select from "react-select";
import { Country, State } from "country-state-city";

class SignUp extends SignUpController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.scrollRef = React.createRef();
    this.state = {
      otp: "",
      confirmotp: "",
      checkedBox: false,
      currentCountryCode: "91",
      newValue: "",
      value: "Customer",
      alertMessage: "",
      alertOpen: false,
      allCountries: "",
      countryFlag: "IN"
    };
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangeCheckBox = (event: any) => {
    this.setState({ termConditionError: false })
    this.setState({ checkedBox: event.target.checked })
  };

  getPasscode(passcode: string) {
    this.setState({ passcodeError: false })
    this.setState({ passcode });
  }

  getConfirmedPasscode(confirmedPasscode: string) {
    this.setState({ confirmPasscodeError: false })
    this.setState({ matchPasscodeError: false })
    this.setState({ confirmedPasscode });
  }
  // hide show modal
  displayModal(show: boolean) {
    this.setState({ isVisible: show })
  }

  displayWhyEmailModal(show: boolean) {
    this.setState({ whyEmail: show })
  }

  checkBoChecked(show: boolean) {
    this.setState({ isCheckBoxChecked: show })
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    StorageProvider.set("user_type", newValue)

  };

  handleWhyEmailModal = () => {
    this.setState({ whyEmailModal: !this.state.whyEmailModal })
  }

  handleTermsModal = () => {
    this.setState({ termsModal: !this.state.termsModal })
  }
  handleAlert = (txt) => {
    this.setState({ alertMessage: txt }, () => {
      this.setState({ alertOpen: true })
    })
  }
  scrollToTop = () => {
    this.scrollRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }
  closeAlert = () => {
    this.setState({ alertOpen: false })
  }

  // this is to get current geolocation and address from geolocation
  requestLocationPermission = async () => {
    this.getOneTimeLocation();
  };


  getOneTimeLocation = () => {
    this.setState({ loading: true });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentLongitude = JSON.stringify(position.coords.longitude);
        const currentLatitude = JSON.stringify(position.coords.latitude);
        this.setState(
          {
            lat: currentLatitude,
            lng: currentLongitude,
          },
          () => {
            !(this.state.address) && this.getAddress(currentLatitude, currentLongitude);
          }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
        );
      },
      (error) => {
      },
      {
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 0,
      }
    );
  };

  getAddress = (lat: any, lng: any) => {
    this.props.showLoader();
    var request = new XMLHttpRequest();
    var method = "GET";
    var url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lng +
      "&sensor=true&key=AIzaSyBI5xkmKUlPoVouVxtAY2prU4ghXF5V0kc";
    var async = true;
    request.open(method, url, async);
    request.onreadystatechange = () => {
      if (request.readyState == 4 && request.status == 200) {
        var data = JSON.parse(request.responseText);
        this.props.hideLoader();
        var address = data.results[0].formatted_address || "";
        this.setState({ address: address });
      }
    };
    request.send();
  };
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    await this.requestLocationPermission();
    await this.getPrivacyPolicyApiCall();
    this.fetchCountry()
    await StorageProvider.get("user_type").then((user_type) => {
      if (user_type) {
        this.setState({
          value: user_type
        });
      }
    });
    // Customizable Area End
  }

  // Customizable Area Start
  fetchCountry() {
    this.setState({
      allCountries: Country.getAllCountries().filter(country => {
        return country.isoCode === this.state.countryFlag
      }).map((country) => ({
        label: country.name,
        value: country.flag,
        ...country
      })),
      selectedCountry: Country.getAllCountries().filter(country => {
        return country.isoCode === this.state.countryFlag
      }).map((country) => ({
        label: country.name,
        value: country.flag,
        ...country
      })),
      selectedState: []
    })

  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentCountryCode !== this.state.currentCountryCode) {
      await this.fetchCountry()
    }
    if (prevState.selectedCountry !== this.state.selectedCountry) {
      this.updatedStates(this.state.selectedCountry[0])
    }
  }

  updatedStates = (countryId) => {
    var states = State.getStatesOfCountry(countryId?.isoCode)
      .map((state) => ({ label: state.name, value: state.name, ...state }))
    this.setState({
      allStates: states.length > 0 ? states : [{ label: countryId.name, name: countryId.name }]
    })
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation, classes } = this.props;
    const { value } = this.state;
    return (
      <form>
        <Grid
          container
          direction="row"
          justifyContent="center"
          className="container-box"
          ref={this.scrollRef}
        >
          <Grid container className="bg-auth">
            <Grid item xs={12} sm={6} className="left-side pb-150">
              <div className="signup-back" onClick={() => this.props.history.push("/")}>
                <span><img src={backArrowIcon} /></span>
                <Typography className={`main-head ${classes.sofiaFont}`}>
                  {configJSON.signUpLabel}
                </Typography>
              </div>
              <Typography className={`label-name ${classes.sofiaFont}`}>
                {configJSON.signUpAs}
              </Typography>
              <Button onClick={(event) => {
                this.handleChange(event, "Customer");
              }}
                className={
                  value === "Customer"
                    ? `auth-btn light ${classes.sofiaFont}`
                    : `auth-btn  ${classes.sofiaFont}`
                }
                variant="contained"
              >
                {configJSON.labelIamUser}
                <img src={userLogo} />
              </Button>
              <Button onClick={(event) => {
                this.handleChange(event, "Merchant");
              }}
                className={
                  value === "Merchant"
                    ? `auth-btn light ${classes.sofiaFont}`
                    : `auth-btn  ${classes.sofiaFont}`
                }
                variant="contained"
              >
                {configJSON.labelIamShoOwner}
                <img src={storeLogo} />
              </Button>
              <Grid className="phoneNumberCont">
                <Typography className={`label-name ${classes.sofiaFont}`}>
                  {configJSON.labelPhoneNumber}
                </Typography>
                <div className="login_number_cont logn">
                  <CountryCodeSelector
                    isAllFlagAvailable={true}
                    disable={true}
                    allowPropChange={true}
                    navigation={navigation}    //Merge Engine::From BDS
                    id={"CountryCodeSelector"} //Merge Engine::From BDS
                    placeHolder={this.state.currentCountryCode} //UI Engine::From Sketch
                    disable={false}  //Merge Engine::From BDS
                    value={this.state.currentCountryCode} //Merge Engine::From BDS
                  />
                  <div className="log-num">
                    <TextField
                      type="number"
                      variant="outlined"
                      className={`number-field ${classes.sofiaFont}`}
                      maxLength={10}
                      required
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                      }}
                      value={this.state.phoneNumber}
                      onChange={(e) => {
                        this.onChangePhoneNumber(e)
                        this.setState({ phoneNumberError: false })
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <p className={`error-label ${classes.sofiaFont}`}>{this.state.phoneNumberError}</p>

              <Typography className={`label-name mt-25 ${classes.sofiaFont}`}>
                {configJSON.labelEmail}
              </Typography>
              <InputBase
                placeholder="davin.wong@mail.com"
                type="text"
                variant="outlined"
                fullWidth
                className="text-field"
                value={this.state.email}
                onChange={(e) => {
                  this.onChangeEmail(e)
                  this.setState({ emailError: false })
                }}
              />
              <p className={`why-email ${classes.sofiaFont}`}
                onClick={this.handleWhyEmailModal}
              >
                Why email?
              </p>
              <p className={`error-label ${classes.sofiaFont}`}>{this.state.emailError}</p>
              <Grid>
                <Typography className={`label-name mt-25 ${classes.sofiaFont}`}>
                  {configJSON.labelCountry}
                </Typography>
                <Select
                  styles={customSelectStyle}
                  placeholder="Select Country"
                  id="country"
                  name="country"
                  label="country"
                  options={this.state.allCountries}
                  onChange={(value) => {
                    this.setState({
                      selectedState: [],
                      selectedCountry: value
                    })
                  }}
                  value={this.state.selectedCountry}
                  isSearchable={false}
                  components={{
                    Menu: () => null,
                    MenuList: () => null,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                />
                <Typography className={`label-name mt-25 ${classes.sofiaFont}`}>
                  {configJSON.labelState}
                </Typography>
                <Select
                  styles={customSelectStyle}
                  placeholder="Select State"
                  id="state"
                  name="state"
                  label="state"
                  value={this.state.selectedState}
                  options={this.state.allStates}
                  onChange={(value) => {
                    this.setState({
                      selectedState: value,
                      stateError: ""
                    })
                  }}
                />
                <p className={`error-label ${classes.sofiaFont}`}>{this.state.stateError}</p>
              </Grid>
              <Grid className="check-box-field">
                <CustomCheckBox
                  testID={"chkBoxTermsAndConditions"}
                  isChecked={this.state.isCheckBoxChecked}
                  onChangeValue={() => this.checkBoChecked(!this.state.isCheckBoxChecked)} />
                <p className={`agree-terms ${classes.sofiaFont}`}>{configJSON.labelIagree}
                  <span className={`text_underline`}
                    onClick={this.handleTermsModal}
                  >
                    {configJSON.labelTerms}
                  </span>
                </p>
              </Grid>
              <p className={`error-label ${classes.sofiaFont}`}>{this.state.termConditionError}</p>
              <Grid className="btn-box sign-box">
                <Button variant="contained" style={{ height: '42px' }} className={`site-lg-btn ${classes.sofiaFont}`}
                  onClick={() => this.validatePhoneAndGetOtpCall()}>
                  {configJSON.labelSingUp}
                </Button>
                <Grid>
                  <p className={`have-acc ${classes.sofiaFont}`}>{configJSON.alreadyHaveAccount}</p>
                  <Button className={`login-btn ${classes.sofiaFont}`}
                    onClick={() => {
                      this.scrollToTop()
                      this.props.history.push("PhoneLoginWeb")
                    }}>
                    <img src={userLogo} />
                    {configJSON.labelTitle}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className="right-side" >
              <img src={colorLogo} onClick={() => this.props.history.push("/")} className="site-logo" />
              <Grid className="right-icons mt-70 log-ico">
                <div className={"icon_cont"}>
                  <span className={"icon_name"}>{configJSON.labeltooltip1}</span>
                  <img src={group3} className={"right-alert-img"} />
                </div>
                <div className={"icon_cont"}>
                  <span className={"icon_name"}>{configJSON.labeltooltip2}</span>
                  <img src={group2} className={"right-alert-img"} />
                </div>
                <div className={"icon_cont"}>
                  <span className={"icon_name"}>{configJSON.labeltooltip3}</span>
                  <img src={group6} className={"right-alert-img"} />
                </div>
              </Grid>
              <Grid className="right-icons log-ico">
                <div className={"icon_cont"}>
                  <span className={"icon_name"}>{configJSON.labeltooltip4}</span>
                  <img src={group4} className={"right-alert-img "} />
                </div>
                <div className={"icon_cont "}>
                  <span className={"icon_name"}>{configJSON.labeltooltip5}</span>
                  <img src={group5} className={"right-alert-img"} />
                </div>
                <div className={"icon_cont"}>
                  <span className={"icon_name"}>{configJSON.labeltooltip6}</span>
                  <img src={group} className={"right-alert-img"} />
                </div>
              </Grid>
              <img src={group8} className="group-logo bt-80" />
            </Grid>
          </Grid>
        </Grid>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.whyEmailModal}
          onClose={this.handleWhyEmailModal}
        // className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <Typography className={`${classes.WhyModalHeading}  ${classes.sofiaFont}`}>{configJSON.labelWhyEmail}</Typography>
            <img className={classes.modalImg} src={group8} alt="" />
            <Typography className={`${classes.WhyModalContent}  ${classes.sofiaFont}`}>{configJSON.labelWhyEmailContent}</Typography>
            <Button
              style={{ backgroundColor: APP_COLORS.app_theme_green_color }}
              className={`${classes.closeModalBtn}  ${classes.sofiaFont}`}
              onClick={this.handleWhyEmailModal}
            >{configJSON.labelClose}</Button>
          </div>
        </Modal>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.termsModal}
          onClose={this.handleTermsModal}
        >
          <div className={classes.modalContent}>
            <Typography className={`${classes.WhyModalHeading}  ${classes.sofiaFont}`}>{configJSON.labelTerms}</Typography>
            <Typography className={`${classes.WhyModalContent}  ${classes.sofiaFont}`}>
              <div style={{ overflow: 'scroll', height: '400px' }} >
                <div dangerouslySetInnerHTML={{ __html: this.state.termAndConditions }} />
              </div>
            </Typography>
            <Button
              style={{ backgroundColor: APP_COLORS.app_theme_green_color }}
              className={`${classes.closeModalBtn}  ${classes.sofiaFont}`}
              onClick={this.handleTermsModal}
            >{configJSON.labelClose}</Button>
          </div>
        </Modal>
      </form>
    )
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  sofiaFont: {
    fontFamily: APP_FONTS.SofiaProRegular
  },
  modalParent: {
    width: "100%",
    position: "relative",
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    display: "flex",
    alignItems: 'center',
    flexDirection: "column",
    justifyContent: "center",
    left: "50%",
    transform: "translate(-50%,-50%)",
    borderRadius: "12px",
    width: "50%",
    padding: "20px",
    backgroundColor: "#fff",
  },
  modalImg: {
    width: "90%",
    objectFit: "contain",
    margin: "10px auto"
  },
  closeModalBtn: {
    fontFamily: APP_FONTS.SofiaProRegular,
    fontSize: "16px",
    width: 100,
    margin: "20px auto",
    borderRadius: 12,
    color: "#ffffff",
    textTransform: "capitalize",
  },
  WhyModalHeading: {
    fontSize: 22,
    margin: "20px auto"
  },
  WhyModalContent: {
    fontSize: 16,
    margin: "10px auto"
  }
})

const customSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#FFFFFF" : "#FFFFFF",
    color: state.isSelected ? "black" : "black",
  }),
}
// Customizable Area End

const SignUpWithLoader = withLoader(SignUp)
const SignUpWithToast = withToast(SignUpWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(SignUpWithToast));