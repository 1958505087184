// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area Start
// Customizable Area End
import { RouterProps  } from "react-router";
export const configJSON = require("./config");

export type Props = RouterProps & {
    id: string;
    classes: any;
    showNotification: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    fullPhoneNumber: string;
    token: string;
    otp: string;
    OTP : string;
    loading: boolean;
    timer: any;
    counter: number;
    otpError: string
    restartTimer : boolean;
    authToken: string
    loginOTPData:any;
    userType_login: string
    fullPhoneNumberLogin : string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class LoginVerifyOtpControllerWeb extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    labelVerifyOtp: string;
    labelSaveBtn: string;
    labelOtp: string;
    labelVerifyOtpMessage: string;
    labelDidntGotOtp: string;
    labelOtpresendIn: string;
    apiVerifyOTPLCallId: string = "";
    attrs: {};
    apiType: string;
    apiGetOTPLCallId:string;
    apiGetLoginOTPLCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage),
            // getName(MessageEnum.AccoutLoginSuccess)
        ]

        this.state = {
            otp: "",
            OTP:"",
            fullPhoneNumber: "",
            token: "",
            timer: null,
            counter: 120,
            loading: false,
            enableField: false,
            otpError: "",
            authToken:"",
            userType_login:"",
            fullPhoneNumberLogin:"",
            restartTimer : false,
            loginOTPData:[]
        };
        // Customizable Area End
        this.labelVerifyOtp = configJSON.labelVerifyOtp;
        this.labelSaveBtn = configJSON.labelSaveBtn;
        this.labelOtp = configJSON.labelOtp;
        this.labelVerifyOtpMessage = configJSON.labelVerifyOtpMessage;
        this.labelDidntGotOtp = configJSON.labelDidntGotOtp;

        this.labelOtpresendIn = configJSON.labelOtpresendIn;
        this.labelDidntGotOtp = configJSON.labelDidntGotOtp;
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async handleLoginResponse(responseJson) {
      this.setState({ loading: false });
    
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        await this.saveUserData(responseJson);
        await this.saveBusinessDetails(responseJson);
        await this.savePayoutDetails(responseJson);
        this.redirectUser(responseJson);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    
    async saveUserData(responseJson) {
      let data = this.extractUserData(responseJson);
    
      await StorageProvider.remove('login_type');
      await StorageProvider.remove('loginData');
      await StorageProvider.set('loginData', JSON.stringify(data));
      await StorageProvider.remove('businessDetails');
    }
    
    extractUserData(responseJson) {
      let data = {
        token: responseJson.meta.token,
        phone: responseJson.meta.account.data.attributes.full_phone_number,
        email: responseJson.meta.account.data.attributes.email,
        name: responseJson.meta.account.data.attributes.name,
        id: responseJson.meta.account.data.id,
        address: responseJson.meta.account.data.attributes.address,
        profile_image_path: responseJson.meta.account.data.attributes.profile_image_path,
        user_type: responseJson.meta.account.data.attributes.user_type,
        is_two_step_verification: responseJson.meta.account.data.attributes.is_two_step_verification,
        is_secured_delivery: responseJson.meta.account.data.attributes.business_details.merchant_details?.is_secured_delivery || false,
        payment_type: responseJson.meta.account.data.attributes.business_details.merchant_details?.payment_type || "Prepaid",
        referral_code: responseJson.meta.account.data.attributes.referral_code,
      };
    
      if (responseJson.meta.account.data.attributes.business_details && responseJson.meta.account.data.attributes.business_details.merchant_details && responseJson.meta.account.data.attributes.business_details.merchant_details.id) {
        if (responseJson.meta.account.data.attributes.business_details.merchant_details.id) {
          data.profile_image_path = responseJson.meta.account.data.attributes.business_details.profile_image_path;
          data.shop_name = responseJson.meta.account.data.attributes.business_details.merchant_details.shop_name;
        }
      }
    
      return data;
    }
    
    async saveBusinessDetails(responseJson) {
      if (responseJson.meta.account.data.attributes.business_details && responseJson.meta.account.data.attributes.business_details.merchant_details && responseJson.meta.account.data.attributes.business_details.merchant_details.id) {
        if (responseJson.meta.account.data.attributes.business_details.merchant_details.id) {
          let data = this.extractBusinessDetails(responseJson);
    
          await StorageProvider.remove('businessDetailsData');
          await StorageProvider.set('businessDetailsData', JSON.stringify(data));
          await StorageProvider.set('businessDetails', "yes");
        } else {
          await StorageProvider.set('businessDetails', "no");
        }
      } else {
        await StorageProvider.set('businessDetails', "no");
      }
    }
    
    extractBusinessDetails(responseJson) {
      let data = {
        shop_name: responseJson.meta.account.data.attributes.business_details.merchant_details.shop_name,
        owner_name: responseJson.meta.account.data.attributes.business_details.merchant_details.owner_name,
        shop_address: responseJson.meta.account.data.attributes.business_details.merchant_details.shop_address,
        gst_number: responseJson.meta.account.data.attributes.business_details.merchant_details.gst_number,
        bhim_upi_number: responseJson.meta.account.data.attributes.business_details.merchant_details.bhim_upi_number,
        email: responseJson.meta.account.data.attributes.business_details.merchant_details.email,
        lat: responseJson.meta.account.data.attributes.business_details.merchant_details.lat,
        lng: responseJson.meta.account.data.attributes.business_details.merchant_details.lng,
        pages: responseJson.meta.account.data.attributes.business_details.merchant_details.page_types,
        print_colours: responseJson.meta.account.data.attributes.business_details.merchant_details.print_colours,
        profile_image_path: responseJson.meta.account.data.attributes.business_details.profile_image_path,
        merchantId: responseJson.meta.account.data.attributes.business_details.merchant_details.id,
        paper_types: responseJson.meta.account.data.attributes.business_details.merchant_details.paper_types,
        is_setting_locked: responseJson.meta.account.data.attributes.business_details.merchant_details.is_setting_locked,
      };
    
      return data;
    }
    
    async savePayoutDetails(responseJson) {
      await StorageProvider.remove('payoutDetails');
    
      if (responseJson.meta.account.data.attributes.bank_details && responseJson.meta.account.data.attributes.bank_details.id) {
        let data = this.extractPayoutDetails(responseJson);
    
        await StorageProvider.remove('payoutDetailsData');
        await StorageProvider.set('payoutDetailsData', JSON.stringify(data));
        await StorageProvider.set('payoutDetails', "yes");
      } else {
        await StorageProvider.set('payoutDetails', "no");
      }
    }
    
    extractPayoutDetails(responseJson) {
      return {
        beneficiary_name: responseJson.meta.account.data.attributes.bank_details.beneficiary_name,
        account_number: responseJson.meta.account.data.attributes.bank_details.account_number,
        bank_name: responseJson.meta.account.data.attributes.bank_details.bank_name,
        ifsc_code: responseJson.meta.account.data.attributes.bank_details.ifsc_code,
        bank_address: responseJson.meta.account.data.attributes.bank_details.bank_address,
      };
    }

    handleMerchantNavigation(responseJson) {
      if (
        responseJson.meta.account.data.attributes.business_details &&
        responseJson.meta.account.data.attributes.business_details.merchant_details &&
        responseJson.meta.account.data.attributes.business_details.merchant_details.id
      ) {
        if (responseJson.meta.account.data.attributes.business_details.merchant_details.bhim_upi_number) {
          this.props.history.push('MerchantSidebarNavigation');
        } else if (responseJson.meta.account.data.attributes.bank_details && responseJson.meta.account.data.attributes.bank_details.id) {
          this.props.history.push('MerchantSidebarNavigation');
        } else {
          this.props.history.push('Merchant/AddMerchantPayoutDetails');
        }
      } else {
        this.props.history.push('Merchant/AddMerchantBusinessDetails');
      }
    }
    
    
    redirectUser(responseJson) {
      if (responseJson.meta.account.data.attributes.user_type == 'Customer') {
        this.props.history.push("UserSidebarNavigation");
      } else if (responseJson.meta.account.data.attributes.user_type == 'Merchant') {
        this.handleMerchantNavigation(responseJson);
      } 
      else {
        this.props.history.push("UserSidebarNavigation");
      }
    }

    async handleOtpResponse(responseJson) {
      this.setState({ loading: false });
      this.props.hideLoader();
    
      if (responseJson && responseJson.data) {
        this.handleOtpSuccess();
      } else {
        this.handleOtpError(responseJson.errors[0].failed_login);
      }
    }
    
    handleOtpSuccess() {
      this.setState({
        restartTimer: true
      });
      this.props.showNotification('OTP Sent successfully', 'success');
    }
    
    handleOtpError(errors) {
      this.props.showNotification(errors, 'error');
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.AlertMessage) === message.id) {
            let AlertBodyMessageLoginVerify = message.getData(
              getName(MessageEnum.AlertBodyMessage)
            );
            this.props.showNotification(AlertBodyMessageLoginVerify, "error");
          } else if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallIdLoginVerifyPage = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallIdLoginVerifyPage === this.apiGetOTPLCallId) {
              this.handleLoginResponse(responseJson);
            }
           else if(apiRequestCallIdLoginVerifyPage === this.apiGetLoginOTPLCallId){
              this.handleOtpResponse(responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    saveLoggedInUserData(responseJson: any) {
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
  
        msg.addData(
          getName(MessageEnum.SessionResponseData),
          JSON.stringify(responseJson)
        );
        msg.addData(
          getName(MessageEnum.SessionResponseToken),
          responseJson.meta.token
        );
        this.send(msg);
      }
    }
     //STRAT --here we are validating the phone number and country code and making API call to login user 
  loginAPICall() {
    let OTP: any = this.state.OTP;
    const validateOtpError = this.validateOTPNumber(OTP);
    if (validateOtpError) {
      this.setState({ otpError: validateOtpError });
      return;
    }
    this.setState({ loading: true });
    this.apiType = "sms_account"
    this.attrs = {
      user_type: this.state.userType_login,
      pin : this.state.OTP ,
      full_phone_number: this.state.fullPhoneNumberLogin,
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
       token: this.state.authToken
    };

    const data = {
      type: this.apiType,
      attributes: this.attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOTPLCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginOtpConfirmApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  resendOtp() {
    this.setState({ loading: true });
    //here we need to call GET OTP API CALL
    this.apiType = "sms_account"
    this.attrs = {
      full_phone_number: this.state.fullPhoneNumberLogin,
      user_type: this.state.userType_login,
    }

    const headerLoginVerify = {
      "Content-Type": configJSON.apiContentType
    };
    this.setState({ loading: true });
    this.props.showLoader();

    const dataLoginVerify = {
      type: this.apiType,
      attributes: this.attrs
    };

    const httpBodyLoginVerify = {
      data: dataLoginVerify
    };

    const requestMessageLoginVerify = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetLoginOTPLCallId = requestMessageLoginVerify.messageId;

    requestMessageLoginVerify.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessageLoginVerify.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerLoginVerify)
    );

    requestMessageLoginVerify.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBodyLoginVerify)
    );

    requestMessageLoginVerify.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessageLoginVerify.id, requestMessageLoginVerify);
  }

  isNonNullAndEmpty(value: string) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateOTPNumber(otp: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "OTP " + configJSON.errorBlankField;
    } else if (!this.isValid4DigitPOTP(otp)) {
      error = configJSON.errorOTPNotValid;
    }
    return error;

  }
  isValid4DigitPOTP(value: string) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 4
    );
  }

    sendAPIFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }


    // Customizable Area End
}
