// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouteProps } from "react-router";

export type Props = StyledProps & RouteProps & {
  id: string;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  phoneNumber: any;
  email: any;
  isVisible: boolean;
  whyEmail: boolean;
  currentCountryCode: any;
  isCheckBoxChecked: boolean;
  passcode: string;
  loading: boolean;
  confirmedPasscode: string;
  otp: any;
  confirmotp: any;
  error: any;
  phoneNumberError: any;
  emailError: any;
  passcodeError: any;
  confirmPasscodeError: any;
  termConditionError: any;
  matchPasscodeError: any;
  lat: string;
  lng: string;
  stateLocation: string;
  termAndConditions: string
  statesList: Array;
  selectedState: string;
  address: any;
  allStates: any;
  stateError: any;
  countryFlag: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelPhoneNumber: string;
  labelNextBtn: string;
  labelLogin: string;
  labelEmail: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  labelSingUp: string;
  phoneReg: RegExp;
  emailReg: RegExp;
  apiType: string;
  attrs: {};

  apiGetOTPLCallId: String = "";
  apiGetTermAndConditon: string = "";
  apiStateList: String = "";
  selectedCountry: String = "";
  selectedState: String = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AlertMessage)
      // getName(MessageEnum.AccoutLoginSuccess)
    ]
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      enableField: false,
      isVisible: false,
      whyEmail: false,
      email: "",
      passcode: "",
      confirmedPasscode: "",
      currentCountryCode: "91",
      isCheckBoxChecked: false,
      loading: false,
      otp: "",
      confirmotp: "",
      error: {},
      phoneNumberError: "",
      emailError: "",
      passcodeError: "",
      confirmPasscodeError: "",
      termConditionError: "",
      matchPasscodeError: "",
      lat: null,
      lng: null,
      stateLocation: '',
      termAndConditions: "",
      statesList: [],
      selectedState: "Other",
      country: "",
      state: "",
      address: "",
      allStates: [],
      stateError: "",
      countryFlag: "IN"
    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelNextBtn = configJSON.labelNextBtn;
    this.labelLogin = configJSON.labelLogin;
    this.labelEmail = configJSON.labelEmail;
    this.labelEnterPhone = configJSON.labelEnterPhone;
    this.labelDontHaveAccount = configJSON.labelDontHaveAccount;
    this.labelSingUp = configJSON.labelSingUp;
    this.phoneReg = new RegExp(/^[0-9]+$/);
    this.emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, 'error');
    } if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (selectedCode !== undefined) {
        this.setState({
          countryFlag: selectedCode?.countryCode,
          currentCountryCode: selectedCode?.value
        });
      }
    } else if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      runEngine.debugLog("errorReponse===>", errorReponse);

      if (apiRequestCallId === this.apiGetTermAndConditon) {
        if (responseJson && responseJson.data) {
          this.setState({ termAndConditions: responseJson?.data?.attributes?.content })
        }
      }

      if (apiRequestCallId === this.apiStateList) {
        if (responseJson?.data) {

          this.setState({
            statesList: responseJson?.data[0]?.state_list?.map(ele => {
              return {
                value: ele, label: ele
              }
            })
          })
        }
      }

      if (apiRequestCallId === this.apiGetOTPLCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          //TODO: Need to uncomment this to go with the login flow
          // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);

          // this.saveLoggedInUserData(responseJson);
          // this.sendLoginSuccessMessage();
          // this.openInfoPage();
          // Alert.alert(responseJson.meta.message);

          // await StorageProvider.remove('loginToken');
          // await StorageProvider.remove('phonenumber');
          // await StorageProvider.remove('email');
          // await StorageProvider.remove('name');
          // await StorageProvider.set('loginToken', JSON.stringify(responseJson.meta.token));
          // await StorageProvider.set('phonenumber', JSON.stringify(responseJson.meta.account.data.attributes.full_phone_number));
          // await StorageProvider.set('email', JSON.stringify(responseJson.meta.account.data.attributes.email));
          // await StorageProvider.set('name', JSON.stringify(responseJson.meta.account.data.attributes.name));
          // this.notifyMessage(responseJson.meta.message);

          // await StorageProvider.remove('verifyToken')
          // await StorageProvider.set('verifyToken', JSON.stringify(responseJson.meta.token));

          // await StorageProvider.remove('signUpPhone')
          // await StorageProvider.set('signUpPhone', JSON.stringify(responseJson.data.attributes.full_phone_number));

          // await StorageProvider.remove('signUpEmail')
          // await StorageProvider.set('signUpEmail', JSON.stringify(this.state.email));

          let verifyToken = await StorageProvider.get('signUpData');
          if (verifyToken) {
            verifyToken = JSON.parse(verifyToken);
          }
          let data: any = { ...verifyToken, token: responseJson?.meta?.token };

          await StorageProvider.remove('signUpData')
          await StorageProvider.set('signUpData', JSON.stringify(data));

          // this.props.navigation.navigate('VerifyOTP', {
          //   token: responseJson.meta.token,
          //   full_phone_number: responseJson.data.attributes.full_phone_number
          // });
          this.props.history.push("VerifyOtp");
          // Alert.alert(responseJson);
        } else {
          //Check Error Response
          // Alert.alert(responseJson);
          this.parseApiErrorResponse(responseJson);
          // this.setState({isError: true, errorMessage:  this.parseApiErrorResponse(responseJson)})
          this.sendAPIFailMessage();
        }

        // this.parseApiCatchErrorResponseNew(errorReponse);
        //this.setState({isError: true, errorMessage:  this.parseApiCatchErrorResponseNew(errorReponse)})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  onChangePhoneNumber = (event: any) => {
    this.setState({ phoneNumber: event.target.value });
  }

  onChangeEmail = (event: any) => {
    this.setState({ email: event.target.value });
  }

  txtInputWebProps = {
    onChange: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputPhoneNumberlWebProps = {
    onChange: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })
        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;


  txtInputEmailWebProps = {
    onChange: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        //@ts-ignore
        this.txtInputEmailProps.value = text
      }
    },
    editable: true
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;


  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  async validatePhoneAndGetOtpCall() {
    let mobileNo: any = this.state.phoneNumber;
    const validatePhoneError = this.validatePhoneNumber(mobileNo);
    if (validatePhoneError) {
      this.setState({ phoneNumberError: validatePhoneError });
      // this.showAlert(configJSON.errorTitle, error);
      return;
    }

    let emailID: any = this.state.email;
    const validateEmailError = this.validateEmail(emailID);
    if (validateEmailError) {
      this.setState({ emailError: validateEmailError });
      return;
    }

    let state: any = this.state.selectedState?.label;
    const validateStateError = this.validateStateName(state);
    if (validateStateError) {
      this.setState({ stateError: validateStateError });
      return;
    }

    let isChecked: boolean = this.state.isCheckBoxChecked;
    if (!isChecked) {
      this.setState({ termConditionError: configJSON.errorTermsAndCondition });
      return;
    }

    this.setState({ loading: true });
    //here we need to call GET OTP API CALL
    let countryCode: any = this.state.currentCountryCode;
    countryCode = countryCode.indexOf("+") > 0
      ? countryCode.split("+")[1]
      : countryCode


    let signUpData: any = {}
    signUpData.phone = countryCode + mobileNo;
    signUpData.mobile = mobileNo;
    signUpData.countryCode = countryCode;
    signUpData.email = this.state.email;
    signUpData.user_type = this.state.value;
    signUpData.state = this.state.selectedState?.label;
    signUpData.country = this.state.selectedCountry?.label;
    signUpData.address = this.state.address
    signUpData.latitude = this.state.lat
    signUpData.longitude = this.state.lng

    console.log(this.state, '------------------');
    await StorageProvider.remove('signUpData');
    await StorageProvider.set('signUpData', JSON.stringify(signUpData));

    //this.apiType = "phone_account"
    this.attrs = {
      full_phone_number: countryCode + mobileNo,
    }

    const header = {
      "Content-Type": configJSON.apiContentType
    };

    const data = {
      attributes: this.attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOTPAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return;
  }

  validatePhoneNumber(phoneNumber: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(phoneNumber)) {
      error = "Phone number " + configJSON.errorBlankField;
    } else if (!this.isValid10DigitPhoneNumber(phoneNumber)) {
      error = configJSON.errorMobileNoNotValid;
    }
    return error;

  }
  validateEmail(email: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(email)) {
      error = "Email " + configJSON.errorBlankField;
    } else if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }
    return error;
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  isValid10DigitPhoneNumber(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 10
    );
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  validateStateName(state: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(state)) {
      error = configJSON.errorEmptyState;
    }
    return error;
  }

  isValid6DigitPasscode(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 6
    );
  }

  matchPasscodeValidation(passcode: string, confirmPasscode: string) {
    let error = null;
    if (passcode != confirmPasscode) {
      error = configJSON.errorPasscodeNotMatched;
    }
    return error;

  }

  //get privacy-policy and Terms and conditions
  getPrivacyPolicyApiCall() {

    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTermAndConditon = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionsAPIEndPoint + `?data[user_type]=customer`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getStateLists() {

    this.setState({ loading: true })
    const header = {
      // "Content-Type": configJSON.loginApiContentType,
      "token": this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiStateList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/account_block/accounts/get_states_list'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
