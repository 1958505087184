// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {
  colorLogo,
  group,
  group2,
  group3,
  group4,
  group5,
  group6,
  group8,
} from "./assets";
// Customizable Area End
import OTPInputView from '../../../components/src/OtpInput.web';
import StorageProvider from "../../../framework/src/StorageProvider";
import LoginVerifyOtpController, {
  Props,
  configJSON
} from "./LoginVerifyOtpController.Web";
import withRouter from "react-router/withRouter";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import "./css/VerifyOtp.css";
// Customizable Area End
class LoginVerifyOtp extends LoginVerifyOtpController{
  // Customizable Area Start
  timer = null;
  otpReference = React.createRef(null);
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getOtp(OTP: string) {
    this.setState({ verifyOtpError: false });
    this.setState({ OTP });
  }

  async componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    this.timer = setInterval(this.manageTimerVerifyLogin, 1000);
  StorageProvider.get("OtpData").then(res=>{
    const OtpToken = JSON.parse(res);
    
    if (OtpToken) {
      this.setState({
          authToken: OtpToken.token,
          fullPhoneNumberLogin :OtpToken.full_phone_number

      });
    }
  })
  await StorageProvider.get("user_type").then((user_type) => {
      this.setState({
        userType_login: user_type ?? "Customer"
      });
  });
    // Customizable Area End
  }

  // Customizable Area Start
  manageTimerVerifyLogin = () => {
    if (this.state.counter > 0) {
      this.setState(({ counter }) => ({
        counter: counter - 1
      }))
    }
    if (this.state.counter === 0) {
      clearInterval(this.timer)
    }
  };

  reStartTimer=()=>{
    if (this.state.restartTimer) {
      this.setState({
        counter: 120
      });
      this.timer = setInterval(this.manageTimerVerifyLogin, 1000);
      this.setState({
        restartTimer: false
      });
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    this.reStartTimer()
    return (
      <Grid container direction="row" justifyContent="center">
        <Grid className={classes.background}>
          <Grid item xs={12} sm={6} className={classes.leftSide}>
          <Box>
            <Typography className={classes.login}>
              {configJSON.labelLogin}
            </Typography>
            <Typography className={classes.verifyOtp}>
              {configJSON.labelVerifyOtp}
            </Typography>
            <Grid>
              <Typography className={classes.weHaveSentAnOne}>
                {this.labelVerifyOtpMessage +
                  "+" +
                  this.state.fullPhoneNumberLogin}
              </Typography>
              <Typography className={classes.didntGetTheOTP}>
                {configJSON.labelDidntGotOtp}
              </Typography>
              {this.state.counter === 0 ? null : (
                <div className={classes.didntGetTheOTP}>
                  <Typography
                    className={classes.didntGetTheOTP}
                    style={{ textDecoration: "underline" }}
                  >
                    Resend In 0{Math.floor(this.state.counter / 60)}:
                    {(this.state.counter % 60).toString().length > 1
                      ? this.state.counter % 60
                      : "0" + (this.state.counter % 60)}
                  </Typography>
                </div>
              )}
              {this.state.counter === 0 ? (
                <div className={classes.didntGetTheOTP}>
                  <Typography
                    testID={"resendButton"}
                    className={classes.didntGetTheOTP}
                    onClick={() => this.resendOtp()}
                    style={{ textDecoration: "underline" }}
                  >
                    Resend OTP
                  </Typography>
                </div>
              ) : null}
            </Grid>
            <Typography className={"OTP"}>{configJSON.labelOtp}</Typography>
            <Grid className={classes.otpinputbox}>
            <OTPInputView OTPLength={4} value={this.state.OTP} className="change-passcode" otpType={"number"}
            onOTPChange={(otp) =>
              this.getOtp(otp)
            }
            secure={false} />
            </Grid>
            <Grid>
            <Typography className={classes.errorLabel}>
              {this.state.otpError}
            </Typography>
            </Grid>
            <p className={classes.errorLabel}>
              {this.state.verifyOtpError}
              {this.state.validateApiError}
              {this.state.errorMessage && this.state.errorMessage.pin}
              {this.state.errorMessage && this.state.errorMessage.phone}
            </p>
            <Button
              variant="contained"
              className={classes.loginButton}
              data-test-id="continue"
              onClick={()=>this.loginAPICall()}
            >
              <p style={{ margin: "0px" }} className={classes.loginButtonTitle}>
                {configJSON.labelLoginContinue}
              </p>
            </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.rightSide}>
            <img
              src={colorLogo}
              data-test-id="siteLogoNav"
              onClick={() => this.props.history?.push("/")}
              className={classes.sitelogo}
            />
            <Grid className={classes.rightIcons} style={{ marginTop: "60px" }}>
              <div className={classes.icon_cont}>
                <span className={classes.icon_name}>{configJSON.labeltooltip1}</span>
                <img src={group3} className={classes.right_alert_img} />
              </div>
              <div className={classes.icon_cont}>
                <span className={classes.icon_name}>{configJSON.labeltooltip2}</span>
                <img src={group2} className={classes.right_alert_img} />
              </div>
              <div className={classes.icon_cont}>
                <span className={classes.icon_name}>{configJSON.labeltooltip3}</span>
                <img src={group6} className={classes.right_alert_img} />
              </div>
            </Grid>
            <Grid className={classes.rightIcons}>
              <div className={classes.icon_cont}>
                <span className={classes.icon_name}>{configJSON.labeltooltip4}</span>
                <img src={group4} className={classes.right_alert_img} />
              </div>
              <div className={classes.icon_cont}>
                <span className={classes.icon_name}>{configJSON.labeltooltip5}</span>
                <img src={group5} className={classes.right_alert_img} />
              </div>
              <div className={classes.icon_cont}>
                <span className={classes.icon_name}>{configJSON.labeltooltip6}</span>
                <img src={group} className={classes.right_alert_img} />
              </div>
            </Grid>
            <img src={group8} className="group-logo" />
          </Grid>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    background: {
      borderRadius: '32px',
      background: '#fff',
      margin: '4%',
      display: 'flex',
      alignItems: 'center',
    },
    leftSide: {
      padding: "60px 50px !important",
    },
    login :{
      fontFamily: '"SofiaPro", sans-serif !important',
      fontSize: "22px !important",
      color: "#1e1f20 !important",
      marginBottom: "40px !important",
    },
    verifyOtp:{
      fontFamily: '"SofiaPro", "sans-serif !important"',
      fontSize: "15px !important",
      color: "#1e1f20 !important",
      marginBottom: "10px !important"
    },
    weHaveSentAnOne:{
      margin: "7px 0 0 !important",
      fontFamily: '"SofiaPro", "sans-serif !important"',
      fontSize: "14px !important",
      color: "#8f92a1 !important",
      display: "inline !important"
    },
    didntGetTheOTP:{
      fontFamily:  '"SofiaPro", "sans-serif !important"',
      fontSize: " 14px !important",
      color: "#565656 !important",
      display: "inline !important",
      paddingLeft: "0.5em !important",
      cursor: "pointer"
    },
    errorLabel:{
      color: "red !important"
    },
    loginButton:{
      width:" 100% !important",
      height: "58px !important",
      margin: "50px 0 20px 0px !important",
      padding: "20px 20px 14px !important",
      borderRadius: "12px !important",
      backgroundColor: "#32795f !important"
    },
    rightIcons:{
      width: "60%",
      display: "flex",
      textAlign: "center",
      margin: "0px auto 10px",
    },
    icon_name:{
      position: "absolute",
      margin: "1px 0",
      fontSize: "12px",
      backgroundColor: "#1e1f20",
      borderRadius: "8px",
      color: "white",
      padding: "5px",
      fontFamily: "sofiaPro",
      minWidth: "120px",
      transition: "all 0.1s ease-in",
      opacity: 0,
      bottom: '50%',
    },
    icon_cont: {
      width: '40%',
      position: 'relative',
      display: 'flex',
      marginTop: '20px',
      flexDirection: 'column',
      alignItems: 'center',
      '&:hover': {
        '& span': {
          opacity: 1,
          bottom: '100%'
        }
      }
    },
    right_alert_img: {
      position: 'relative',
    },
    sitelogo: {
      float: 'right',
      cursor: 'pointer',
    },
    loginButtonTitle: {
      width: '100%',
      height: '20px',
      fontFamily: '"SofiaPro", sans-serif',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight:' 1.43',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#ffffff',
    },
    rightSide: {
      padding: '40px',
      background: '#fdd001',
      position: 'relative',
      minHeight: '569px',
      borderRadius: '32px'
    },
    otpinputbox: {
      '& input': {
        height: '48px !important' ,
        width: '48px !important',
        borderRadius: '4px !important',
        marginRight: '9px !important',
      }
    }
  });
// Customizable Area End"
const LoginVerifyOtpWithLoader = withLoader(LoginVerifyOtp);
const LoginVerifyOtpWithToast = withToast(LoginVerifyOtpWithLoader);
export { LoginVerifyOtp };
export default withRouter(withStyles(styles, { withTheme: true }) (LoginVerifyOtpWithToast));